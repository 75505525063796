import { defineStore } from 'pinia';
import { roundNumber } from '~/utils/number';
import type { PairDataInterface } from '~/types/pairData';

const namespace = 'calculatorstore';

export const useCalculatorDataStore = defineStore(namespace, () => {
  const fromCurrencyID = ref<number>();
  const toCurrencyID = ref<number>();
  const pairData = ref<PairDataInterface | null>(null);
  const course = ref<number | null>(null);

  const fromValue = ref<number | null>(null);
  const toValue = ref<number | null>(null);

  const promo = ref<string | null>(null);
  const refferal = ref<string | null>(null);
  const recaptcha = ref('');

  const setFromValue = (value: number) => {
    fromValue.value = value;
  };

  const setToValue = (value: number) => {
    toValue.value = value;
  };

  function setFromCurrencyID(value: number) {
    fromCurrencyID.value = value;
  }

  function setToCurrencyID(value: number) {
    toCurrencyID.value = value;
  }

  function setPairData(value: PairDataInterface) {
    pairData.value = value;
  }

  function setCourse(value: number) {
    course.value = value;
  }

  function setPromo(value: string) {
    promo.value = value;
  }

  function setRefferal(value: string) {
    refferal.value = value;
  }

  function updateValues() {
    if (!pairData.value || !course.value) {
      return;
    }
    const minValue = pairData.value.from.min
      ? pairData.value.from.min
      : pairData?.value.to.min / course.value;
    setFromValue(minValue);
    const toValue = roundNumber(
      minValue * course.value,
      pairData.value.to.roundCalculator
    );
    setToValue(toValue);
  }

  watch(
    pairData,
    () => {
      if (pairData.value) {
        course.value = pairData.value.course;
        updateValues();
      }
    },
    {
      immediate: true,
    }
  );

  return {
    fromCurrencyID,
    setFromCurrencyID,
    toCurrencyID,
    setToCurrencyID,
    pairData,
    setPairData,
    course,
    fromValue,
    setFromValue,
    toValue,
    setToValue,
    updateValues,
    setCourse,
    promo,
    setPromo,
    refferal,
    setRefferal,
    recaptcha,
  };
});
