import type { UseFetchOptions } from '#app';
import { defu } from 'defu';
import type { NitroFetchOptions } from 'nitropack';
import useAuth from '~/composables/user/useAuth';

export function useApiFetch<T>(url: string, options: UseFetchOptions<T> = {}) {
  const actualUrl = localeUrl(url.toString());
  const defaults: UseFetchOptions<T> = {
    baseURL: getBaseUrl(),
  };
  const params = defu(options, defaults);
  return useFetch(actualUrl, params);
}

export function apiFetch<T>(url: string, options: NitroFetchOptions<any> = {}) {
  const actualUrl = localeUrl(url.toString());
  return useAuth().$fetch(actualUrl, options) as Promise<T>;
}
