<template>
  <div class="v-input-thousands v-input-thousands--default" :class="classList">
    <div class="v-input-thousands__wrapper">
      <div class="v-input-thousands__inner-no-padding">
        <transition name="fade" mode="out-in">
          <!--                    <div v-if="loading" class="v-input-thousands__loader"></div>-->
          <input
            v-if="!loading"
            ref="input"
            class="v-input-thousands__native"
            :aria-label="label"
            v-bind="$attrs"
            :value="textValue ? textValue : splittedValue"
            type="text"
            :disabled="disabled"
            @keypress="checkForNumber"
            @keydown.enter="$refs.input.blur()"
            @input="onInput"
            @change="onChange"
            @focus="onFocus"
            @blur="onBlur"
          />
        </transition>
      </div>
    </div>
    <span v-if="label" class="v-input-thousands__label">
      {{ label }}
    </span>
    <div class="v-input-thousands__addon">
      <span v-if="currency" class="v-input-thousands__addon-currency">
        {{ currency }}
      </span>
      <div v-if="addon" class="v-input-thousands__addon-image">
        <img :src="addon" alt="" class="v-input-thousands__addon-image" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VInputThousands',

  props: {
    value: {
      type: [String, Number],
      default: '',
    },

    label: {
      type: String,
      default: '',
    },

    postfix: {
      type: String,
      default: '',
    },

    affix: {
      type: String,
      default: '',
    },

    /**
     * Определяет классы, которые будут модифицировать размер
     */
    size: {
      type: String,
      default: 'medium',
      validator(value) {
        return ['large', 'medium', 'small', 'inline'].includes(value);
      },
    },

    color: {
      type: String,
      default: 'default',
      validator(value) {
        return ['default', 'shop'].includes(value);
      },
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    delimiter: {
      type: String,
      default: ' ',
    },

    decimalMark: {
      type: String,
      default: '.',
    },

    positiveOnly: {
      type: Boolean,
      default: true,
    },

    isSingle: {
      type: Boolean,
      default: false,
    },

    decimalCount: {
      type: Number,
      default: 6,
    },

    textValue: {
      type: String,
      default: '',
    },

    addon: {
      type: String,
      default: '',
    },

    currency: {
      type: String,
      default: '',
    },

    isError: Boolean,

    loading: Boolean,
  },
  emits: ['input', 'blur', 'focus', 'change'],

  data() {
    return {
      isFocused: false,
      splittedValue: this.splitThousands(this.value),
    };
  },

  computed: {
    classList() {
      return [
        `v-input-thousands--${this.color}`,
        `v-input-thousands--${this.size}`,
        {
          'is-active': this.value,
          'is-focused': this.isFocused,
          'is-disabled': this.disabled,
          'is-single': this.isSingle,
          'has-error': this.isError,
        },
      ];
    },

    cleanValue() {
      return Number(this.splittedValue.split(this.delimiter).join(''));
    },
  },

  watch: {
    value(newValue) {
      if (newValue !== this.cleanValue) {
        this.splittedValue = this.splitThousands(newValue);
      }
    },
  },

  methods: {
    checkForNumber(event) {
      if (
        isNaN(Number(event.key)) &&
        (event.key !== '.' || this.decimalCount === 0)
      ) {
        return event.preventDefault();
      }
    },
    onChange() {
      // strip any leading zeros
      if (this.splittedValue.length > 1 && this.splittedValue[0] === '0') {
        this.splittedValue = this.splitThousands(
          this.cleanValue.toString().replace(/^(-)?0+(?=\d)/, '$1')
        );
      }
      this.$nextTick(() => {
        this.$emit('change', this.cleanValue);
      });
    },

    onFocus(e) {
      this.isFocused = true;
      this.$emit('focus', e);
    },

    onBlur(e) {
      this.isFocused = false;
      this.$emit('blur', e);
    },

    onInput(e) {
      let endPos = e.target.selectionEnd;
      const oldValue = e.target.value;
      const newValue = e.target.value; // Здесь было const newValue = this.splitThousands(e.target.value), но в сафари в таком случае значение не сбрасывается до минимального, если ввести значение ниже минимального;
      e.target.value = newValue;
      this.splittedValue = newValue;

      this.$nextTick(() => {
        endPos = this.getNextCursorPosition(
          endPos,
          oldValue,
          newValue,
          this.delimiter
        );
        this.setCursor(e.target, endPos);
        this.$emit('input', this.cleanValue);
      });
    },

    splitThousands(value) {
      if (typeof value !== 'number' && typeof value !== 'string') {
        console.warn('[CInputThousands] Wrong prop value');
        return '';
      }

      // const partDecimal = '';
      // let parts;

      // strip alphabet letters
      value = value.toString();
      value = value
        .replace(/[A-Za-z]/g, '')
        // replace the first decimal mark with reserved placeholder
        .replace(this.decimalMark, 'M')

        // strip non numeric letters except minus and "M"
        // this is to ensure prefix has been stripped
        .replace(/[^\dM-]/g, '')

        // replace the leading minus with reserved placeholder
        .replace(/^-/, 'N')

        // strip the other minus sign (if present)
        .replace(/-/g, '')

        // replace the minus sign (if present)
        .replace('N', this.positiveOnly ? '' : '-')

        // replace decimal mark
        .replace('M', this.decimalMark);

      // strip any leading zeros
      // if (owner.stripLeadingZeroes) {
      // value = value.replace(/^(-)?0+(?=\d)/, '$1');
      // }

      const partSign = value.slice(0, 1) === '-' ? '-' : '';
      const partSignAndPrefix = partSign;
      const partInteger = roundNumber(value, this.decimalCount);

      // if (value.includes(this.decimalMark)) {
      //     parts = value.split(this.decimalMark);
      //     partInteger = parts[0];
      //     partDecimal = this.decimalMark + parts[1].slice(0, this.decimalCount);
      // }
      // if (partSign === '-') {
      //     partInteger = partInteger.slice(1);
      // }
      // partInteger = partInteger.replace(/(\d)(?=(\d{3})+$)/g, '$1' + this.delimiter);

      return (
        partSignAndPrefix + partInteger.toString()
        // + (this.decimalCount > 0 ? partDecimal.toString() : '')
      );
    },

    getNextCursorPosition(prevPos, oldValue, newValue, delimiter) {
      return oldValue.length === prevPos
        ? newValue.length
        : prevPos +
            this.getPositionOffset(prevPos, oldValue, newValue, delimiter);
    },

    getPositionOffset(prevPos, oldValue, newValue, delimiter) {
      const oldRawValue = this.stripDelimiters(
        oldValue.slice(0, prevPos),
        delimiter
      );
      const newRawValue = this.stripDelimiters(
        newValue.slice(0, prevPos),
        delimiter
      );
      const lengthOffset = oldRawValue.length - newRawValue.length;
      return lengthOffset !== 0 ? lengthOffset / Math.abs(lengthOffset) : 0;
    },

    stripDelimiters(value, delimiter) {
      const delimiterRE = delimiter
        ? new RegExp(delimiter.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1'), 'g')
        : '';
      return value.replace(delimiterRE, '');
    },

    setCursor(el, position) {
      const setSelectionRange = function () {
        el.setSelectionRange(position, position);
      };

      if (el === document.activeElement) {
        setSelectionRange();
        // Android Fix
        setTimeout(setSelectionRange, 1);
      }
    },
  },
};
</script>

<style lang="scss">
.v-input-thousands {
  position: relative;

  .v-input-thousands__wrapper {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 2.3rem;
    border-radius: $border-radius-l;
    font-size: 2rem;
    line-height: 2.3rem;
    border: 1px solid transparent;
    transition:
      border-color 0.4s ease,
      background-color 0.4s ease,
      color 0.4s ease;
  }

  &--default {
    .v-input-thousands__label {
      color: $base-100;
    }

    .v-input-thousands__native {
      color: $base-100;
    }

    &:not(.is-inversed) {
      .v-input-thousands__addon-image {
        filter: grayscale(1);
      }

      .v-input-thousands__wrapper {
        height: 6rem;
        background: linear-gradient(
          90deg,
          rgba(#223540, 0.5) 0%,
          rgba(#1d2d36, 0.5) 100%
        );
      }

      &.is-focused {
        .v-input-thousands__wrapper {
          .v-input-thousands__label {
            &:after {
              content: '';
            }
          }
        }
      }

      &.is-disabled {
        .v-input-thousands__addon-image {
          filter: grayscale(0);
        }

        .v-input-thousands__wrapper {
          border-color: $base-0;
          background-color: #eff5fb;

          &:before {
            background: $base-0;
          }
        }

        .v-input-thousands__label {
          color: $base-300;
        }

        .v-input-thousands__native {
          color: $base-600;
        }
      }
    }
  }

  &--shop {
    .v-input-thousands__label {
      color: $base-100;
    }

    .v-input-thousands__native {
      color: $base-100;
    }

    &:not(.is-inversed) {
      .v-input-thousands__addon-image {
        filter: grayscale(1);
      }

      .v-input-thousands__wrapper {
        height: 5.5rem;
        background: #375464;
      }

      &.is-focused {
        .v-input-thousands__wrapper {
          .v-input-thousands__label {
            &:after {
              content: '';
            }
          }
        }
      }

      &.is-disabled {
        .v-input-thousands__addon-image {
          filter: grayscale(0);
        }

        .v-input-thousands__wrapper {
          border-color: $base-0;
          background-color: #eff5fb;

          &:before {
            background: $base-0;
          }
        }

        .v-input-thousands__label {
          color: $base-300;
        }

        .v-input-thousands__native {
          color: $base-600;
        }
      }
    }
  }

  &.is-active {
    .v-input-thousands__label {
      font-size: 1.2rem;
      line-height: 1.4rem;
      color: rgba($base-0, 0.5);
      transform: translateY(-130%);
    }
  }

  &.has-label {
    .v-input-thousands__native {
      font-size: 1.6rem;
      line-height: 1.9rem;
      margin-top: 0.5rem;
    }
  }

  &.is-focused {
    .v-input-thousands__premask_wrap {
      opacity: 1;

      span {
        opacity: 1;
      }
    }

    .v-input-thousands__label {
      font-size: 1.2rem;
      line-height: 1.4rem;
      color: rgba($base-0, 0.5);
      transform: translateY(-130%);
    }
  }

  &.has-error {
    .v-input-thousands__wrapper {
      border-color: #b65454;
    }
  }

  &.is-disabled {
    pointer-events: none;
  }

  &.is-copy {
    .v-input__wrapper {
      border-radius: $border-radius-default;
    }

    .v-input__addon {
      cursor: pointer;
      z-index: 2;
    }
  }

  //&.is-readonly {
  //    .v-input__native {
  //        pointer-events:;
  //    }
  //}
  &__premask_wrap {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    width: 100%;
    height: calc(100% - 1.8rem);
    margin-top: 1.8rem;
    font-size: inherit;
    color: currentColor;
    opacity: 0;
    transition: 0.3s all ease;
  }

  &__inner {
    position: relative;
    display: inline-flex;
    width: 100%;
    height: 100%;
    padding-top: 1.8rem;
  }

  &__inner-no-padding {
    position: relative;
    width: 100%;
    height: 100%;
  }

  &__loader {
    position: absolute;
    z-index: 3;
    top: 50%;
    height: 25px;
    width: 89px;
    background-color: hsl(0deg 0% 39% / 30%);
    border-radius: 5rem;
    transform: translateY(-50%);
  }

  &__native {
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    border: none;
    background-color: transparent;
    outline: none;
    font-size: inherit;
    color: $base-1000;
    box-shadow: none;
    -webkit-appearance: none;

    &:-webkit-autofill,
    &:-webkit-autofill:focus {
      transition:
        background-color 600000s 0s,
        color 600000s 0s;
    }
  }

  &__label {
    z-index: 1;
    position: absolute;
    top: 50%;
    left: 3rem;
    font-size: 1.6rem;
    color: $base-0;
    pointer-events: none;
    transform: translateY(-50%);
    transition: all 0.3s ease;
  }

  &__error {
    position: absolute;
    bottom: -2rem;
    left: 3rem;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.4rem;
    letter-spacing: -0.02em;
    color: red;
  }

  &__addon {
    position: absolute;
    right: 2rem;
    top: 50%;
    display: flex;
    align-items: center;
    transform: translateY(-50%);
  }

  &__addon-image {
    width: 2rem;
    height: 2rem;
  }

  &__addon-currency {
    margin-right: 0.5rem;
    font-size: 2rem;
    line-height: 1rem;
    color: $base-500;
  }
}
</style>
