<template>
  <button
    type="button"
    :class="[
      $style.HomeExchangeSwap,
      { [$style._disabled]: !isSwappable },
      { [$style.shop]: shop },
    ]"
    :disabled="isLoading"
    @click="$emit('onSwap')"
  >
    <IconsSwap :class="$style.icon" />
  </button>
</template>

<script setup lang="ts">
import { calculatorApi } from '~/utils/api/calculator';

const props = defineProps<{
  fromId: number;
  toId: number;
  shop?: boolean;
}>();

const isSwappable = ref(false);
const isLoading = ref(false);

defineEmits<(e: 'onSwap') => void>();

async function checkSwappable() {
  try {
    isLoading.value = true;
    await calculatorApi.getCourse({
      from: props.toId,
      to: props.fromId,
    });
    isSwappable.value = true;
  } catch (error) {
    isSwappable.value = false;
  } finally {
    isLoading.value = false;
  }
}

watch([() => props.fromId, () => props.toId], async () => {
  if (props.fromId === props.toId) return;
  await checkSwappable();
});

onMounted(async () => {
  await checkSwappable();
});
</script>

<style lang="scss" module>
.HomeExchangeSwap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 6rem;
  height: 6rem;
  background-color: $brand-green;
  border-radius: 50%;
  font-size: 2.8rem;
  color: $base-0;
  cursor: pointer;
  flex-shrink: 0;
  transition: background-color 0.3s ease-in-out;

  @include respond-to(sm) {
    margin: 0 auto 0 auto;
  }

  @include respond-to(xs) {
    width: 42px;
    height: 42px;
    margin: 0 0 0 auto;
    font-size: 18px;
    color: #1d2d36;
  }

  &._disabled {
    background-color: $green-dark;
    pointer-events: none;
  }
}

.icon {
  width: 2.8rem;
  height: 2.8rem;
  color: $base-0;

  @include respond-to(xs) {
    width: 18px;
    height: 18px;
  }
}

.shop {
  width: 3rem;
  height: 3rem;

  @include respond-to(xs) {
    display: none;
  }

  & .icon {
    width: 1.3rem;
    height: 1.3rem;
  }
}
</style>
