import type { RouteLocationNormalized, RouteLocationRaw } from 'vue-router';

export default async function authMiddleware(to: RouteLocationNormalized) {
  const nuxtApp = useNuxtApp();
  const { auth: authMeta } = to.meta;
  const { $auth } = nuxtApp;
  const {
    public: { auth: authConfig },
  } = useRuntimeConfig();
  const loginPath = authConfig.redirect.login ?? '/';
  const loginRoute: RouteLocationRaw = {
    path: loginPath,
    query: { status: AuthStatus.Unauthorized },
  };
  const homePath = authConfig.redirect.home ?? '/';

  const isLoggedIn = $auth.hasTokens();
  const isSessionEnd = $auth.isSessionEnd();
  const isSessionExpired = $auth.isSessionExpired();
  const isGuestAuth = authMeta === 'guest';
  const isAuthRequired = authMeta === true;
  const isAuthDefined = authMeta && !isLoggedIn;
  const shouldSetReferer = String(authMeta) !== 'guest' && isLoggedIn;
  const isCurrentRouteLogin = String(to.path) === String(loginRoute?.path);

  if (isLoggedIn && !$auth.isPersistent) {
    $auth.logout(false);
    return navigateTo(to);
  }

  if (isGuestAuth) {
    if (isLoggedIn) {
      return navigateTo(homePath);
    }
    return;
  }
  if (isLoggedIn) {
    const refreshAccessToken = async () => {
      if (isSessionExpired) await $auth.refreshTokens();
    };
    const fetchUser = async () => {
      if (!$auth.user.value) await $auth.fetchUser();
    };
    try {
      await refreshAccessToken();
      await fetchUser();
    } catch (error) {
      if (isSessionEnd) $auth.logout(false);
      if (isAuthRequired) {
        if (shouldSetReferer) $auth.setReferer(to.fullPath);
        return nuxtApp.runWithContext(() =>
          navigateTo({
            path: loginPath,
            query: { status: AuthStatus.Expired },
          })
        );
      }
    }
  }

  if (isAuthDefined && !isCurrentRouteLogin) {
    $auth.setReferer(to.fullPath);
    return nuxtApp.runWithContext(() => navigateTo(loginRoute?.path));
  }
}
