<template>
  <BaseModal
    :class="$style.LoginModal"
    login
    :visible="visible"
    @after-enter="$emit('after-enter')"
    @after-leave="$emit('after-leave')"
    @close="$emit('close')"
  >
    <div :class="$style.title">
      {{ $t('authorization') }}
    </div>
    <form
      :class="$style.form"
      @submit.prevent="onSubmit"
      @keydown.enter.prevent="onSubmit"
    >
      <VInput
        v-model="email"
        placeholder="E-mail"
        type="email"
        name="email"
        required
      />
      <VInput
        v-model="password"
        :placeholder="$t('password')"
        type="password"
        name="password"
        required
      />
      <p v-if="errorMsg" :class="$style.error">{{ errorMsg }}</p>
      <div :class="$style.bottom">
        <div :class="$style.check">
          <VCheckbox v-model="remember">
            <div :class="$style.text">
              {{ $t('remember me') }}
            </div>
          </VCheckbox>
        </div>
        <div :class="$style.forgot" @click="onForgetClick">
          {{ $t('forgot your password?') }}
        </div>
      </div>
      <div :class="$style.button">
        <UiVButton
          size="large"
          color="primary"
          responsive
          type="submit"
          spinner
          :loading="isLoading"
        >
          {{ $t('login') }}
        </UiVButton>
      </div>
      <div :class="$style.register">
        {{ $t('Dont have an account?') }}
        <span :class="$style.forgot" @click="onRegisterClick()">{{
          $t('registration 2')
        }}</span>
      </div>
    </form>
  </BaseModal>
</template>

<script setup>
import BaseModal from './BaseModal.vue';
import RegisterModal from '~/components/common/RegisterModal.vue';
import ForgetModal from '~/components/common/ForgetModal.vue';
import VCheckbox from '~/components/ui/Checkbox/VCheckbox.vue';
import VInput from '~/components/ui/Input/VInput.vue';

const { $modal } = useNuxtApp();
defineProps({
  visible: {
    type: Boolean,
    default: false,
  },
});

defineEmits(['close', 'after-enter', 'after-leave']);
const remember = ref(false);
function onRegisterClick() {
  $modal.open(markRaw(RegisterModal));
}

function onForgetClick() {
  $modal.open(markRaw(ForgetModal));
}

const email = ref('');
const password = ref('');
const isLoading = ref(false);

const { login, errorMsg } = useLogin({});

async function onSubmit() {
  isLoading.value = true;
  const details = {
    email: email.value.toLowerCase().trim(),
    password: password.value,
  };
  await login(details);
  isLoading.value = false;
}
</script>

<style lang="scss" module>
.LoginModal {
  //
}

.title {
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: $base-100;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 1.4rem;
  margin-top: 22px;

  @include respond-to(xs) {
    margin-top: 25px;
  }
}

.input {
  &:not(:last-child) {
    margin-bottom: 13px;

    @include respond-to(xs) {
      margin-bottom: 11px;
    }
  }
}

.bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 19px;
}

.check {
  display: flex;
  align-items: center;
}

.text {
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #a7a7a7;
}

.forgot {
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  text-decoration-line: underline;
  cursor: pointer;

  &:hover {
    color: $base-100;
  }
}

.button {
  margin-top: 18px;
}

.register {
  margin-top: 18px;
  font-size: 16px;
  line-height: 18px;
  text-align: center;

  /* dsgn_text */

  color: #a7a7a7;
}

.error {
  font-size: 14px;
  color: $error-color;
}
</style>
