import { default as apisLYaJS93m7Meta } from "/workdir/pages/account/api.vue?macro=true";
import { default as indexy5gfVaMJcXMeta } from "/workdir/pages/account/index.vue?macro=true";
import { default as link_45generationSXRYArhf2UMeta } from "/workdir/pages/account/link-generation.vue?macro=true";
import { default as referralsaVNIuBXIxiMeta } from "/workdir/pages/account/referrals.vue?macro=true";
import { default as sales_45history6EUEQUNAA9Meta } from "/workdir/pages/account/sales-history.vue?macro=true";
import { default as settings12lTJKMTnGMeta } from "/workdir/pages/account/settings.vue?macro=true";
import { default as statisticsjWFP5lWnkbMeta } from "/workdir/pages/account/statistics.vue?macro=true";
import { default as withdrawalPlw7wvqpmIMeta } from "/workdir/pages/account/withdrawal.vue?macro=true";
import { default as accountQy3Qfs0j0GMeta } from "/workdir/pages/account.vue?macro=true";
import { default as amlNyEmYaREQ4Meta } from "/workdir/pages/aml.vue?macro=true";
import { default as _91slug_9338vCBfDOUZMeta } from "/workdir/pages/blog/[slug].vue?macro=true";
import { default as indexny9yMOgZ5uMeta } from "/workdir/pages/blog/index.vue?macro=true";
import { default as indexL2wOIS17NHMeta } from "/workdir/pages/claim/[uuid]/index.vue?macro=true";
import { default as failXdaHQUDRb0Meta } from "/workdir/pages/claim/fail.vue?macro=true";
import { default as indexIPJFi2Je1FMeta } from "/workdir/pages/claim/index.vue?macro=true";
import { default as successLtUxxRtTjZMeta } from "/workdir/pages/claim/success.vue?macro=true";
import { default as contactsdOknjXgzjVMeta } from "/workdir/pages/contacts.vue?macro=true";
import { default as FaqiKlBnKZEJnMeta } from "/workdir/pages/Faq.vue?macro=true";
import { default as indexJ1sPgYGdnvMeta } from "/workdir/pages/index.vue?macro=true";
import { default as partnersiuYTY69Lz6Meta } from "/workdir/pages/partners.vue?macro=true";
import { default as indexk3qcO1cw9bMeta } from "/workdir/pages/rules/index.vue?macro=true";
import { default as shopsyoe5oVHiiSMeta } from "/workdir/pages/shops.vue?macro=true";
import { default as sitemapIkRc3b092bMeta } from "/workdir/pages/sitemap.vue?macro=true";
import { default as _91uuid_93o1tIXBxpXUMeta } from "/workdir/pages/verification/[uuid].vue?macro=true";
export default [
  {
    path: accountQy3Qfs0j0GMeta?.path ?? "/account",
    children: [
  {
    name: apisLYaJS93m7Meta?.name ?? "account-api___ru",
    path: apisLYaJS93m7Meta?.path ?? "api",
    meta: apisLYaJS93m7Meta || {},
    alias: apisLYaJS93m7Meta?.alias || [],
    redirect: apisLYaJS93m7Meta?.redirect || undefined,
    component: () => import("/workdir/pages/account/api.vue").then(m => m.default || m)
  },
  {
    name: indexy5gfVaMJcXMeta?.name ?? "account___ru",
    path: indexy5gfVaMJcXMeta?.path ?? "",
    meta: indexy5gfVaMJcXMeta || {},
    alias: indexy5gfVaMJcXMeta?.alias || [],
    redirect: indexy5gfVaMJcXMeta?.redirect || undefined,
    component: () => import("/workdir/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: link_45generationSXRYArhf2UMeta?.name ?? "account-link-generation___ru",
    path: link_45generationSXRYArhf2UMeta?.path ?? "link-generation",
    meta: link_45generationSXRYArhf2UMeta || {},
    alias: link_45generationSXRYArhf2UMeta?.alias || [],
    redirect: link_45generationSXRYArhf2UMeta?.redirect || undefined,
    component: () => import("/workdir/pages/account/link-generation.vue").then(m => m.default || m)
  },
  {
    name: referralsaVNIuBXIxiMeta?.name ?? "account-referrals___ru",
    path: referralsaVNIuBXIxiMeta?.path ?? "referrals",
    meta: referralsaVNIuBXIxiMeta || {},
    alias: referralsaVNIuBXIxiMeta?.alias || [],
    redirect: referralsaVNIuBXIxiMeta?.redirect || undefined,
    component: () => import("/workdir/pages/account/referrals.vue").then(m => m.default || m)
  },
  {
    name: sales_45history6EUEQUNAA9Meta?.name ?? "account-sales-history___ru",
    path: sales_45history6EUEQUNAA9Meta?.path ?? "sales-history",
    meta: sales_45history6EUEQUNAA9Meta || {},
    alias: sales_45history6EUEQUNAA9Meta?.alias || [],
    redirect: sales_45history6EUEQUNAA9Meta?.redirect || undefined,
    component: () => import("/workdir/pages/account/sales-history.vue").then(m => m.default || m)
  },
  {
    name: settings12lTJKMTnGMeta?.name ?? "account-settings___ru",
    path: settings12lTJKMTnGMeta?.path ?? "settings",
    meta: settings12lTJKMTnGMeta || {},
    alias: settings12lTJKMTnGMeta?.alias || [],
    redirect: settings12lTJKMTnGMeta?.redirect || undefined,
    component: () => import("/workdir/pages/account/settings.vue").then(m => m.default || m)
  },
  {
    name: statisticsjWFP5lWnkbMeta?.name ?? "account-statistics___ru",
    path: statisticsjWFP5lWnkbMeta?.path ?? "statistics",
    meta: statisticsjWFP5lWnkbMeta || {},
    alias: statisticsjWFP5lWnkbMeta?.alias || [],
    redirect: statisticsjWFP5lWnkbMeta?.redirect || undefined,
    component: () => import("/workdir/pages/account/statistics.vue").then(m => m.default || m)
  },
  {
    name: withdrawalPlw7wvqpmIMeta?.name ?? "account-withdrawal___ru",
    path: withdrawalPlw7wvqpmIMeta?.path ?? "withdrawal",
    meta: withdrawalPlw7wvqpmIMeta || {},
    alias: withdrawalPlw7wvqpmIMeta?.alias || [],
    redirect: withdrawalPlw7wvqpmIMeta?.redirect || undefined,
    component: () => import("/workdir/pages/account/withdrawal.vue").then(m => m.default || m)
  }
],
    name: accountQy3Qfs0j0GMeta?.name ?? undefined,
    meta: accountQy3Qfs0j0GMeta || {},
    alias: accountQy3Qfs0j0GMeta?.alias || [],
    redirect: accountQy3Qfs0j0GMeta?.redirect || undefined,
    component: () => import("/workdir/pages/account.vue").then(m => m.default || m)
  },
  {
    path: accountQy3Qfs0j0GMeta?.path ?? "/en/account",
    children: [
  {
    name: apisLYaJS93m7Meta?.name ?? "account-api___en",
    path: apisLYaJS93m7Meta?.path ?? "api",
    meta: apisLYaJS93m7Meta || {},
    alias: apisLYaJS93m7Meta?.alias || [],
    redirect: apisLYaJS93m7Meta?.redirect || undefined,
    component: () => import("/workdir/pages/account/api.vue").then(m => m.default || m)
  },
  {
    name: indexy5gfVaMJcXMeta?.name ?? "account___en",
    path: indexy5gfVaMJcXMeta?.path ?? "",
    meta: indexy5gfVaMJcXMeta || {},
    alias: indexy5gfVaMJcXMeta?.alias || [],
    redirect: indexy5gfVaMJcXMeta?.redirect || undefined,
    component: () => import("/workdir/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: link_45generationSXRYArhf2UMeta?.name ?? "account-link-generation___en",
    path: link_45generationSXRYArhf2UMeta?.path ?? "link-generation",
    meta: link_45generationSXRYArhf2UMeta || {},
    alias: link_45generationSXRYArhf2UMeta?.alias || [],
    redirect: link_45generationSXRYArhf2UMeta?.redirect || undefined,
    component: () => import("/workdir/pages/account/link-generation.vue").then(m => m.default || m)
  },
  {
    name: referralsaVNIuBXIxiMeta?.name ?? "account-referrals___en",
    path: referralsaVNIuBXIxiMeta?.path ?? "referrals",
    meta: referralsaVNIuBXIxiMeta || {},
    alias: referralsaVNIuBXIxiMeta?.alias || [],
    redirect: referralsaVNIuBXIxiMeta?.redirect || undefined,
    component: () => import("/workdir/pages/account/referrals.vue").then(m => m.default || m)
  },
  {
    name: sales_45history6EUEQUNAA9Meta?.name ?? "account-sales-history___en",
    path: sales_45history6EUEQUNAA9Meta?.path ?? "sales-history",
    meta: sales_45history6EUEQUNAA9Meta || {},
    alias: sales_45history6EUEQUNAA9Meta?.alias || [],
    redirect: sales_45history6EUEQUNAA9Meta?.redirect || undefined,
    component: () => import("/workdir/pages/account/sales-history.vue").then(m => m.default || m)
  },
  {
    name: settings12lTJKMTnGMeta?.name ?? "account-settings___en",
    path: settings12lTJKMTnGMeta?.path ?? "settings",
    meta: settings12lTJKMTnGMeta || {},
    alias: settings12lTJKMTnGMeta?.alias || [],
    redirect: settings12lTJKMTnGMeta?.redirect || undefined,
    component: () => import("/workdir/pages/account/settings.vue").then(m => m.default || m)
  },
  {
    name: statisticsjWFP5lWnkbMeta?.name ?? "account-statistics___en",
    path: statisticsjWFP5lWnkbMeta?.path ?? "statistics",
    meta: statisticsjWFP5lWnkbMeta || {},
    alias: statisticsjWFP5lWnkbMeta?.alias || [],
    redirect: statisticsjWFP5lWnkbMeta?.redirect || undefined,
    component: () => import("/workdir/pages/account/statistics.vue").then(m => m.default || m)
  },
  {
    name: withdrawalPlw7wvqpmIMeta?.name ?? "account-withdrawal___en",
    path: withdrawalPlw7wvqpmIMeta?.path ?? "withdrawal",
    meta: withdrawalPlw7wvqpmIMeta || {},
    alias: withdrawalPlw7wvqpmIMeta?.alias || [],
    redirect: withdrawalPlw7wvqpmIMeta?.redirect || undefined,
    component: () => import("/workdir/pages/account/withdrawal.vue").then(m => m.default || m)
  }
],
    name: accountQy3Qfs0j0GMeta?.name ?? undefined,
    meta: accountQy3Qfs0j0GMeta || {},
    alias: accountQy3Qfs0j0GMeta?.alias || [],
    redirect: accountQy3Qfs0j0GMeta?.redirect || undefined,
    component: () => import("/workdir/pages/account.vue").then(m => m.default || m)
  },
  {
    name: amlNyEmYaREQ4Meta?.name ?? "aml___ru",
    path: amlNyEmYaREQ4Meta?.path ?? "/aml",
    meta: amlNyEmYaREQ4Meta || {},
    alias: amlNyEmYaREQ4Meta?.alias || [],
    redirect: amlNyEmYaREQ4Meta?.redirect || undefined,
    component: () => import("/workdir/pages/aml.vue").then(m => m.default || m)
  },
  {
    name: amlNyEmYaREQ4Meta?.name ?? "aml___en",
    path: amlNyEmYaREQ4Meta?.path ?? "/en/aml",
    meta: amlNyEmYaREQ4Meta || {},
    alias: amlNyEmYaREQ4Meta?.alias || [],
    redirect: amlNyEmYaREQ4Meta?.redirect || undefined,
    component: () => import("/workdir/pages/aml.vue").then(m => m.default || m)
  },
  {
    name: _91slug_9338vCBfDOUZMeta?.name ?? "blog-slug___ru",
    path: _91slug_9338vCBfDOUZMeta?.path ?? "/blog/:slug()",
    meta: _91slug_9338vCBfDOUZMeta || {},
    alias: _91slug_9338vCBfDOUZMeta?.alias || [],
    redirect: _91slug_9338vCBfDOUZMeta?.redirect || undefined,
    component: () => import("/workdir/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9338vCBfDOUZMeta?.name ?? "blog-slug___en",
    path: _91slug_9338vCBfDOUZMeta?.path ?? "/en/blog/:slug()",
    meta: _91slug_9338vCBfDOUZMeta || {},
    alias: _91slug_9338vCBfDOUZMeta?.alias || [],
    redirect: _91slug_9338vCBfDOUZMeta?.redirect || undefined,
    component: () => import("/workdir/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexny9yMOgZ5uMeta?.name ?? "blog___ru",
    path: indexny9yMOgZ5uMeta?.path ?? "/blog",
    meta: indexny9yMOgZ5uMeta || {},
    alias: indexny9yMOgZ5uMeta?.alias || [],
    redirect: indexny9yMOgZ5uMeta?.redirect || undefined,
    component: () => import("/workdir/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: indexny9yMOgZ5uMeta?.name ?? "blog___en",
    path: indexny9yMOgZ5uMeta?.path ?? "/en/blog",
    meta: indexny9yMOgZ5uMeta || {},
    alias: indexny9yMOgZ5uMeta?.alias || [],
    redirect: indexny9yMOgZ5uMeta?.redirect || undefined,
    component: () => import("/workdir/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: indexL2wOIS17NHMeta?.name ?? "claim-uuid___ru",
    path: indexL2wOIS17NHMeta?.path ?? "/claim/:uuid()",
    meta: indexL2wOIS17NHMeta || {},
    alias: indexL2wOIS17NHMeta?.alias || [],
    redirect: indexL2wOIS17NHMeta?.redirect || undefined,
    component: () => import("/workdir/pages/claim/[uuid]/index.vue").then(m => m.default || m)
  },
  {
    name: indexL2wOIS17NHMeta?.name ?? "claim-uuid___en",
    path: indexL2wOIS17NHMeta?.path ?? "/en/claim/:uuid()",
    meta: indexL2wOIS17NHMeta || {},
    alias: indexL2wOIS17NHMeta?.alias || [],
    redirect: indexL2wOIS17NHMeta?.redirect || undefined,
    component: () => import("/workdir/pages/claim/[uuid]/index.vue").then(m => m.default || m)
  },
  {
    name: failXdaHQUDRb0Meta?.name ?? "claim-fail___ru",
    path: failXdaHQUDRb0Meta?.path ?? "/claim/fail",
    meta: failXdaHQUDRb0Meta || {},
    alias: failXdaHQUDRb0Meta?.alias || [],
    redirect: failXdaHQUDRb0Meta?.redirect || undefined,
    component: () => import("/workdir/pages/claim/fail.vue").then(m => m.default || m)
  },
  {
    name: failXdaHQUDRb0Meta?.name ?? "claim-fail___en",
    path: failXdaHQUDRb0Meta?.path ?? "/en/claim/fail",
    meta: failXdaHQUDRb0Meta || {},
    alias: failXdaHQUDRb0Meta?.alias || [],
    redirect: failXdaHQUDRb0Meta?.redirect || undefined,
    component: () => import("/workdir/pages/claim/fail.vue").then(m => m.default || m)
  },
  {
    name: indexIPJFi2Je1FMeta?.name ?? "claim___ru",
    path: indexIPJFi2Je1FMeta?.path ?? "/claim",
    meta: indexIPJFi2Je1FMeta || {},
    alias: indexIPJFi2Je1FMeta?.alias || [],
    redirect: indexIPJFi2Je1FMeta?.redirect || undefined,
    component: () => import("/workdir/pages/claim/index.vue").then(m => m.default || m)
  },
  {
    name: indexIPJFi2Je1FMeta?.name ?? "claim___en",
    path: indexIPJFi2Je1FMeta?.path ?? "/en/claim",
    meta: indexIPJFi2Je1FMeta || {},
    alias: indexIPJFi2Je1FMeta?.alias || [],
    redirect: indexIPJFi2Je1FMeta?.redirect || undefined,
    component: () => import("/workdir/pages/claim/index.vue").then(m => m.default || m)
  },
  {
    name: successLtUxxRtTjZMeta?.name ?? "claim-success___ru",
    path: successLtUxxRtTjZMeta?.path ?? "/claim/success",
    meta: successLtUxxRtTjZMeta || {},
    alias: successLtUxxRtTjZMeta?.alias || [],
    redirect: successLtUxxRtTjZMeta?.redirect || undefined,
    component: () => import("/workdir/pages/claim/success.vue").then(m => m.default || m)
  },
  {
    name: successLtUxxRtTjZMeta?.name ?? "claim-success___en",
    path: successLtUxxRtTjZMeta?.path ?? "/en/claim/success",
    meta: successLtUxxRtTjZMeta || {},
    alias: successLtUxxRtTjZMeta?.alias || [],
    redirect: successLtUxxRtTjZMeta?.redirect || undefined,
    component: () => import("/workdir/pages/claim/success.vue").then(m => m.default || m)
  },
  {
    name: contactsdOknjXgzjVMeta?.name ?? "contacts___ru",
    path: contactsdOknjXgzjVMeta?.path ?? "/contacts",
    meta: contactsdOknjXgzjVMeta || {},
    alias: contactsdOknjXgzjVMeta?.alias || [],
    redirect: contactsdOknjXgzjVMeta?.redirect || undefined,
    component: () => import("/workdir/pages/contacts.vue").then(m => m.default || m)
  },
  {
    name: contactsdOknjXgzjVMeta?.name ?? "contacts___en",
    path: contactsdOknjXgzjVMeta?.path ?? "/en/contacts",
    meta: contactsdOknjXgzjVMeta || {},
    alias: contactsdOknjXgzjVMeta?.alias || [],
    redirect: contactsdOknjXgzjVMeta?.redirect || undefined,
    component: () => import("/workdir/pages/contacts.vue").then(m => m.default || m)
  },
  {
    name: FaqiKlBnKZEJnMeta?.name ?? "Faq___ru",
    path: FaqiKlBnKZEJnMeta?.path ?? "/Faq",
    meta: FaqiKlBnKZEJnMeta || {},
    alias: FaqiKlBnKZEJnMeta?.alias || [],
    redirect: FaqiKlBnKZEJnMeta?.redirect || undefined,
    component: () => import("/workdir/pages/Faq.vue").then(m => m.default || m)
  },
  {
    name: FaqiKlBnKZEJnMeta?.name ?? "Faq___en",
    path: FaqiKlBnKZEJnMeta?.path ?? "/en/Faq",
    meta: FaqiKlBnKZEJnMeta || {},
    alias: FaqiKlBnKZEJnMeta?.alias || [],
    redirect: FaqiKlBnKZEJnMeta?.redirect || undefined,
    component: () => import("/workdir/pages/Faq.vue").then(m => m.default || m)
  },
  {
    name: indexJ1sPgYGdnvMeta?.name ?? "index___ru",
    path: indexJ1sPgYGdnvMeta?.path ?? "/",
    meta: indexJ1sPgYGdnvMeta || {},
    alias: indexJ1sPgYGdnvMeta?.alias || [],
    redirect: indexJ1sPgYGdnvMeta?.redirect || undefined,
    component: () => import("/workdir/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexJ1sPgYGdnvMeta?.name ?? "index___en",
    path: indexJ1sPgYGdnvMeta?.path ?? "/en",
    meta: indexJ1sPgYGdnvMeta || {},
    alias: indexJ1sPgYGdnvMeta?.alias || [],
    redirect: indexJ1sPgYGdnvMeta?.redirect || undefined,
    component: () => import("/workdir/pages/index.vue").then(m => m.default || m)
  },
  {
    name: partnersiuYTY69Lz6Meta?.name ?? "partners___ru",
    path: partnersiuYTY69Lz6Meta?.path ?? "/partners",
    meta: partnersiuYTY69Lz6Meta || {},
    alias: partnersiuYTY69Lz6Meta?.alias || [],
    redirect: partnersiuYTY69Lz6Meta?.redirect || undefined,
    component: () => import("/workdir/pages/partners.vue").then(m => m.default || m)
  },
  {
    name: partnersiuYTY69Lz6Meta?.name ?? "partners___en",
    path: partnersiuYTY69Lz6Meta?.path ?? "/en/partners",
    meta: partnersiuYTY69Lz6Meta || {},
    alias: partnersiuYTY69Lz6Meta?.alias || [],
    redirect: partnersiuYTY69Lz6Meta?.redirect || undefined,
    component: () => import("/workdir/pages/partners.vue").then(m => m.default || m)
  },
  {
    name: indexk3qcO1cw9bMeta?.name ?? "rules___ru",
    path: indexk3qcO1cw9bMeta?.path ?? "/rules",
    meta: indexk3qcO1cw9bMeta || {},
    alias: indexk3qcO1cw9bMeta?.alias || [],
    redirect: indexk3qcO1cw9bMeta?.redirect || undefined,
    component: () => import("/workdir/pages/rules/index.vue").then(m => m.default || m)
  },
  {
    name: indexk3qcO1cw9bMeta?.name ?? "rules___en",
    path: indexk3qcO1cw9bMeta?.path ?? "/en/rules",
    meta: indexk3qcO1cw9bMeta || {},
    alias: indexk3qcO1cw9bMeta?.alias || [],
    redirect: indexk3qcO1cw9bMeta?.redirect || undefined,
    component: () => import("/workdir/pages/rules/index.vue").then(m => m.default || m)
  },
  {
    name: shopsyoe5oVHiiSMeta?.name ?? "shops___ru",
    path: shopsyoe5oVHiiSMeta?.path ?? "/shops",
    meta: shopsyoe5oVHiiSMeta || {},
    alias: shopsyoe5oVHiiSMeta?.alias || [],
    redirect: shopsyoe5oVHiiSMeta?.redirect || undefined,
    component: () => import("/workdir/pages/shops.vue").then(m => m.default || m)
  },
  {
    name: shopsyoe5oVHiiSMeta?.name ?? "shops___en",
    path: shopsyoe5oVHiiSMeta?.path ?? "/en/shops",
    meta: shopsyoe5oVHiiSMeta || {},
    alias: shopsyoe5oVHiiSMeta?.alias || [],
    redirect: shopsyoe5oVHiiSMeta?.redirect || undefined,
    component: () => import("/workdir/pages/shops.vue").then(m => m.default || m)
  },
  {
    name: sitemapIkRc3b092bMeta?.name ?? "sitemap___ru",
    path: sitemapIkRc3b092bMeta?.path ?? "/sitemap",
    meta: sitemapIkRc3b092bMeta || {},
    alias: sitemapIkRc3b092bMeta?.alias || [],
    redirect: sitemapIkRc3b092bMeta?.redirect || undefined,
    component: () => import("/workdir/pages/sitemap.vue").then(m => m.default || m)
  },
  {
    name: sitemapIkRc3b092bMeta?.name ?? "sitemap___en",
    path: sitemapIkRc3b092bMeta?.path ?? "/en/sitemap",
    meta: sitemapIkRc3b092bMeta || {},
    alias: sitemapIkRc3b092bMeta?.alias || [],
    redirect: sitemapIkRc3b092bMeta?.redirect || undefined,
    component: () => import("/workdir/pages/sitemap.vue").then(m => m.default || m)
  },
  {
    name: _91uuid_93o1tIXBxpXUMeta?.name ?? "verification-uuid___ru",
    path: _91uuid_93o1tIXBxpXUMeta?.path ?? "/verification/:uuid()",
    meta: _91uuid_93o1tIXBxpXUMeta || {},
    alias: _91uuid_93o1tIXBxpXUMeta?.alias || [],
    redirect: _91uuid_93o1tIXBxpXUMeta?.redirect || undefined,
    component: () => import("/workdir/pages/verification/[uuid].vue").then(m => m.default || m)
  },
  {
    name: _91uuid_93o1tIXBxpXUMeta?.name ?? "verification-uuid___en",
    path: _91uuid_93o1tIXBxpXUMeta?.path ?? "/en/verification/:uuid()",
    meta: _91uuid_93o1tIXBxpXUMeta || {},
    alias: _91uuid_93o1tIXBxpXUMeta?.alias || [],
    redirect: _91uuid_93o1tIXBxpXUMeta?.redirect || undefined,
    component: () => import("/workdir/pages/verification/[uuid].vue").then(m => m.default || m)
  }
]