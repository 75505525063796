export function formatTime(time: number): string {
  const days = Math.floor(time / (3600 * 24));
  const hours = Math.floor((time % (3600 * 24)) / 3600)
    .toString()
    .padStart(2, '0');
  const minutes = Math.floor((time % 3600) / 60)
    .toString()
    .padStart(2, '0');
  const seconds = Math.floor(time % 60)
    .toString()
    .padStart(2, '0');

  return `${days ? `${days} дней` : ''}  ${hours}:${minutes}:${seconds}`;
}

const defaultsOptions: Intl.DateTimeFormatOptions = {
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
};

export function convertDate(date: string, options = defaultsOptions): string {
  return new Date(date).toLocaleDateString('ru-RU', options);
}
