<template>
  <div :class="$style.HomeExchangeBlock">
    <transition name="fade-fast">
      <HomeExchangeTechMode v-if="isTechMode" :time="time" />
    </transition>
    <div :class="$style.top">
      <HomeExchangeCalculator
        :directions="directions"
        :from-directions="fromDirections"
        :to-directions="toDirections"
        :from-direction="fromDirection"
        :to-direction="toDirection"
        :from-value="fromValue"
        :to-value="toValue"
        :from-round="pairData?.from.roundCalculator"
        :to-round="pairData?.to.roundCalculator"
        :from-title="fromTitle"
        :to-title="toTitle"
        :congestion="pairData?.structure?.congestion"
        :from-currency-id="fromCurrencyID"
        :to-currency-id="toCurrencyID"
        @update:handle-input-from="handleInputFrom"
        @update:handle-input-to="handleInputTo"
        @update:handle-change-from="handleChangeFrom"
        @update:handle-change-to="handleChangeTo"
        @on-swap="handleCurrenciesSwap"
      />
      <UiVButton
        :disabled="status === 'pending'"
        icon
        @click="$router.push(localePath('/claim'))"
      >
        <template #icon>
          <IconsExchange :class="$style.icon" />
        </template>
        {{ btnText }}
      </UiVButton>
    </div>
    <div :class="$style.bottom">
      <ExchangeCourse :class="$style.course" />
      <div :class="$style.right">
        <IconsHelp :class="$style.help" />
        <div :class="$style.info" @click="openInstructionModal">
          {{ howToMakeAnExchange }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import HomeExchangeCalculator from '~/components/pages/home/exchange/HomeExchangeCalculator.vue';
import ExchangeCourse from '~/components/pages/home/exchange/ExchangeCourse.vue';
import InstructionModal from '~/components/common/InstructionModal.vue';
import HomeExchangeTechMode from '~/components/pages/home/exchange/HomeExchangeTechMode.vue';
import { useCalculatorDataStore } from '~/stores/calculator';
import { calculatorApi } from '~/utils/api/calculator';
import {
  getDirectionItem,
  getDirectionsList,
} from '~/utils/calculator/directions';
import NotificationModal from '~/components/common/NotificationModal.vue';
import type { Direction } from '~/types/calculator';

const localePath = useLocalePath();

interface HomeExchangeProps {
  isTechMode?: boolean;
  directions: Direction[];
  time?: number;
}

const props = withDefaults(defineProps<HomeExchangeProps>(), {
  isTechMode: false,
  time: 0,
});

const { $modal } = useNuxtApp();

const openInstructionModal = () => {
  $modal.open(markRaw(InstructionModal));
};

const { t } = useI18n({
  useScope: 'local',
});

const btnText = computed(() => t('Обменять'));
const howToMakeAnExchange = computed(() => t('Как совершить обмен'));

const calculatorStore = useCalculatorDataStore();
const { fromCurrencyID, toCurrencyID, course, fromValue, toValue } =
  storeToRefs(calculatorStore);
const { data: settings } = await calculatorApi.fetchSettings();

if (settings.value?.exchangeAttributes) {
  calculatorStore.setFromCurrencyID(
    settings.value.exchangeAttributes.fromCurrencyId
  );
  calculatorStore.setToCurrencyID(
    settings.value.exchangeAttributes.toCurrencyId
  );
}

const { data: fromDirectionsIDs, error: fromDirectionsError } =
  await useAsyncData(() => calculatorApi.getFromCurrencies());

if (fromDirectionsError.value && !fromCurrencyID.value) {
  console.error(fromDirectionsError.value);
}

if (!fromCurrencyID.value && fromDirectionsIDs.value) {
  calculatorStore.setFromCurrencyID(fromDirectionsIDs.value[0]);
}

const {
  data: toDirectionsIDs,
  error: toDirectionsError,
  refresh: updateToDirectionsIDs,
} = await useAsyncData(() =>
  calculatorApi.getToCurrencies(fromCurrencyID.value!)
);

if (toDirectionsError.value && !toCurrencyID.value) {
  console.error(toDirectionsError.value);
}

if (!toCurrencyID.value && toDirectionsIDs.value) {
  calculatorStore.setToCurrencyID(toDirectionsIDs.value[0]);
}

const onFromCurrencyChange = async (val: number) => {
  calculatorStore.setFromCurrencyID(val);
  if (!fromCurrencyID.value) {
    return;
  }
  await updateToDirectionsIDs();
  if (!toDirectionsIDs.value) {
    return;
  }
  if (!toDirectionsIDs.value.includes(toCurrencyID.value!)) {
    calculatorStore.setToCurrencyID(toDirectionsIDs.value[0]);
  }
  await updatePairData();
};

const onToCurrencyChange = async (val: number) => {
  calculatorStore.setToCurrencyID(val);
  if (!toCurrencyID.value) {
    return;
  }
  await updatePairData();
};

useEventBus('calculator-direction:from').on(onFromCurrencyChange);
useEventBus('calculator-direction:to').on(onToCurrencyChange);

async function handleCurrenciesSwap() {
  if (!fromCurrencyID.value || !toCurrencyID.value) return;
  const temp = fromCurrencyID.value;
  calculatorStore.setFromCurrencyID(toCurrencyID.value);
  calculatorStore.setToCurrencyID(temp);
  await updateToDirectionsIDs();
  await updatePairData();
}

const {
  data: pairData,
  error: pairDataError,
  refresh: updatePairData,
  status,
} = await useAsyncData(() =>
  calculatorApi.getPairData({
    from: fromCurrencyID.value!,
    to: toCurrencyID.value!,
  })
);

if (pairDataError.value) {
  console.error(pairDataError.value);
}

if (pairData.value) {
  calculatorStore.setPairData(pairData.value);
  calculatorStore.setCourse(pairData.value.course);
  calculatorStore.updateValues();
}

watch(pairData, () => {
  if (!pairData.value) {
    return;
  }
  calculatorStore.setPairData(pairData.value);
});

const fromDirection = computed(() => {
  if (!fromCurrencyID.value) return null;
  return getDirectionItem(props.directions, fromCurrencyID.value);
});

const toDirection = computed(() => {
  if (!toCurrencyID.value) return null;
  return getDirectionItem(props.directions, toCurrencyID.value);
});

const fromDirections = computed(() => {
  if (!fromDirectionsIDs.value) return [];
  return getDirectionsList(props.directions, fromDirectionsIDs.value);
});

const toDirections = computed(() => {
  if (!toDirectionsIDs.value) return [];
  return getDirectionsList(props.directions, toDirectionsIDs.value);
});

const limits = computed(() => {
  const from = pairData.value?.from;
  const to = pairData.value?.to;
  if (!from || !to)
    return {
      from: {
        min: 0,
        max: 0,
      },
      to: {
        min: 0,
        max: 0,
      },
    };
  return {
    from: {
      min: from.min ? from.min : to.min / course.value!,
      max: from.max,
    },
    to: {
      min: to.min ? to.min : from.min * course.value!,
      max: to.max,
    },
  };
});

const fromTitle = computed(() => {
  if (!limits.value) return t('Отдаёте');
  return `${t('Отдаёте')} (${t('мин. сумма')} ${roundNumber(
    limits.value.from.min,
    pairData.value?.from.roundCalculator
  )} ${fromDirection.value?.currency?.[0]}.)`;
});

const toTitle = computed(() => t('Получаете'));

const notifications = computed(() => {
  if (!pairData) return null;
  return {
    from: pairData.value?.from.notify,
    to: pairData.value?.to.notify,
  };
});

watch(
  notifications,
  (value) => {
    if (value.from || value.to) {
      const notification = [];
      if (value.from) {
        notification.push({
          message: value.from,
          name: fromDirection.value?.name,
        });
      }
      if (value.to) {
        notification.push({
          message: value.to,
          name: toDirection.value?.name,
        });
      }
      setTimeout(() => {
        $modal.open(markRaw(NotificationModal), { notification });
      }, 0);
    }
  },
  {
    deep: true,
    immediate: true,
  }
);

function handleInputFrom(val: number) {
  fromValue.value = val;
  toValue.value = roundNumber(
    val * course.value!,
    pairData.value?.to.roundCalculator
  );
}

function handleInputTo(val: number) {
  toValue.value = val;
  fromValue.value = roundNumber(
    val / course.value!,
    pairData.value?.from.roundCalculator
  );
}

function handleChangeFrom(val: number) {
  if (!pairData?.value) return;
  const min = pairData.value.from.min
    ? pairData?.value.from.min
    : pairData?.value.to.min / course.value!;
  const max = pairData.value.from.max;
  if (val < min) {
    calculatorStore.setFromValue(min);
    const toVal = roundNumber(
      min * course.value!,
      pairData.value.to.roundCalculator
    );
    calculatorStore.setToValue(toVal);
  } else if (val > max) {
    calculatorStore.setFromValue(max);
    const toVal = roundNumber(
      max * course.value!,
      pairData.value.to.roundCalculator
    );
    calculatorStore.setToValue(toVal);
  }
}

function handleChangeTo(val: number) {
  handleChangeFrom(
    roundNumber(val / course.value!, pairData.value?.from.roundCalculator)
  );
}
</script>

<i18n lang="json">
{
  "ru": {
    "Обменять": "Обменять",
    "Как совершить обмен": "Как совершить обмен",
    "Отдаёте": "Отдаёте",
    "Получаете": "Получаете",
    "мин. сумма": "мин. сумма"
  },
  "en": {
    "Обменять": "Exchange",
    "Как совершить обмен": "How to make an exchange",
    "Отдаёте": "Send",
    "Получаете": "You receive",
    "мин. сумма": "min. amount"
  }
}
</i18n>

<style lang="scss" module>
.HomeExchangeBlock {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  min-height: 18rem;
  margin-top: 5rem;
  padding: 1.8rem 3.2rem;
  background-color: rgba(#1d2532, 0.5);
  border-radius: $border-radius-default;

  @include respond-to(sm) {
    min-height: 440px;
    margin-top: 3rem;
    padding: 1.8rem 2.4rem;
  }

  @include respond-to(xs) {
    min-height: 400px;
    padding: 34px 18px;
    background-color: rgba(#11151d, 0.5);
  }
}

.top {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 2rem;

  @include respond-to(sm) {
    flex-direction: column;
    align-items: stretch;
    gap: 15px;
  }
}

.swap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 6rem;
  height: 6rem;
  background-color: $brand-green;
  border-radius: 50%;
  font-size: 2.8rem;
  color: $base-0;
  cursor: pointer;

  @include respond-to(sm) {
    margin: 0 auto 0 auto;
  }

  @include respond-to(xs) {
    width: 42px;
    height: 42px;
    margin: 0 0 0 auto;
    font-size: 18px;
    color: #1d2d36;
  }
}

.iconSwap {
  width: 2.8rem;
  height: 2.8rem;
  color: $base-0;

  @include respond-to(xs) {
    width: 18px;
    height: 18px;
  }
}

.icon {
  width: 1.6rem;
  height: 1.6rem;
  color: $body-bg;
}

.count {
  height: 24px;
  width: 24px;

  span {
    font-size: 12px;
    line-height: 14px;
  }
}

.course {
  @include respond-to(xs) {
    position: absolute;
    top: 27%;
    left: 39px;
    width: 75%;
  }
}

.help {
  width: 1.8rem;
  height: 1.8rem;
  color: $brand-green;
}

.bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include respond-to(xs) {
    justify-content: center;
  }
}

.left {
  display: flex;
  align-items: center;
  gap: 1rem;

  @include respond-to(xs) {
    position: absolute;
    top: 27%;
    left: 39px;
    width: 75%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 5px;
    color: white;
  }
}

.leftTitle {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.right {
  display: flex;
  align-items: center;
}

.info {
  width: 13.5rem;
  margin-left: 0.8rem;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.4rem;
  text-decoration-line: underline;
  cursor: pointer;
  transition: color $base-transition;

  &:hover {
    color: $brand-green;
  }
}

.input {
  position: relative;
}

.directions {
  @include respond-to(sm) {
    display: none;
  }
}
</style>
