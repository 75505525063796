export const useLogout = (redirectPath = '/') => {
  const pending = ref(false);
  const errorMsg = ref('');
  const { $auth } = useNuxtApp();
  const {
    public: { auth: authConfig },
  } = useRuntimeConfig();
  const redirectRoute = (authConfig.redirect.logout || redirectPath) ?? '/';

  const doLogout = () => {
    return $auth
      .logout()
      .then(() => {
        navigateTo(redirectRoute, { replace: true });
      })
      .catch((error) => {
        errorMsg.value = error.data.message;
      })
      .finally(() => (pending.value = false));
  };

  return {
    errorMsg,
    pending,
    doLogout,
  };
};
