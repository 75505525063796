<template>
  <NuxtLayout>
    <NuxtLoadingIndicator color="#1d2d36" />
    <NuxtPage />
    <DefaultTheModal />
  </NuxtLayout>
</template>

<script setup lang="ts">
const { locale } = useI18n();
const jivoSrc = computed(() => {
  if (locale.value === 'ru') {
    return '//code.jivosite.com/widget/yR9ekiR3AG';
  } else {
    return '//code.jivosite.com/widget/ZodBWaS3mj';
  }
});

const i18nHead = useLocaleHead({
  addDirAttribute: true,
  identifierAttribute: 'id',
  addSeoAttributes: true,
});

useHead({
  htmlAttrs: {
    lang: i18nHead.value.htmlAttrs?.lang,
  },
  script: [
    {
      src: () => jivoSrc.value,
      async: true,
    },
  ],
});
</script>
