import { apiFetch, useApiFetch } from '~/utils/fetch/fetchApi';
import type { Course, Direction, PairData, Reserve } from '~/types/calculator';
import useFetchWithCache from '~/composables/useFetchWithCache';

const calculatorApiUrl = {
  getDirectionsUrl: '/api/v1/calculator/',
  getCourseUrl: (from: number, to: number) =>
    `/api/v1/calculator/course/${from}/${to}/`,
  getFromCurrenciesUrl: '/api/v1/calculator/from/',
  getToCurrenciesUrl: (from: number) => `/api/v1/calculator/from/${from}/`,
  getPairDataUrl: (from: number, to: number) =>
    `/api/v1/calculator/pair/${from}/${to}/`,
  getReservesUrl: '/api/v1/calculator/reserves/',
  getSettingsUrl: '/api/v1/calculator/settings/',
  getTechUrl: '/api/v1/calculator/tech/',
};

export const calculatorApi = {
  getDirections,
  getCourse,
  getFromCurrencies,
  getToCurrencies,
  getPairData,
  getReserves,
  initCalculator,
  pair,
  fetchTech,
  fetchSettings,
};

function getDirections() {
  return useFetchWithCache<Direction[]>(calculatorApiUrl.getDirectionsUrl);
}

function getCourse({ from, to }: { from: number; to: number }) {
  return apiFetch<Course>(calculatorApiUrl.getCourseUrl(from, to), {
    auth: true,
  });
}

function getFromCurrencies() {
  return apiFetch<number[]>(calculatorApiUrl.getFromCurrenciesUrl, {
    auth: false,
  });
}

function getToCurrencies(from: number) {
  return apiFetch<number[]>(calculatorApiUrl.getToCurrenciesUrl(from), {
    auth: false,
  });
}

function getPairData({ from, to }: { from: number; to: number }) {
  return apiFetch<PairData>(calculatorApiUrl.getPairDataUrl(from, to), {
    auth: true,
  });
}

function getReserves() {
  return useApiFetch<Reserve[]>(calculatorApiUrl.getReservesUrl, {
    server: false,
    lazy: true,
  });
}

function pair(from: number, to: number) {
  return apiFetch<PairData>(`/api/v1/calculator/pair/${from}/${to}/`);
}

function initCalculator({
  cur_from,
  cur_to,
  locale,
}: {
  cur_from: string;
  cur_to: string;
  locale: string;
}) {
  return useFetch('/front-api/calculator/currencies', {
    query: {
      cur_from,
      cur_to,
      locale,
    },
  });
}
interface fetchTechResponse {
  technicalMode: {
    time: number;
    on: boolean;
  };
}
function fetchTech() {
  return useApiFetch<fetchTechResponse>('/api/v1/calculator/tech/', {
    lazy: true,
  });
}

interface Filter {
  name: string;
  code: string;
}

interface SettingsExchangeAttributesInterface {
  fromCurrencyId: number;
  toCurrencyId: number;
}

interface fetchSettingsResponse {
  filters: {
    main: Filter[];
    alternative: Filter[];
  };
  exchangeAttributes?: SettingsExchangeAttributesInterface;
  contest: boolean;
}

function fetchSettings() {
  return useApiFetch<fetchSettingsResponse>('/api/v1/calculator/settings/', {
    lazy: true,
    pick: ['filters', 'contest', 'exchangeAttributes'],
  });
}
