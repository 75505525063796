<template>
  <div :class="$style.IndexPage" class="container">
    <h1 :class="$style.title">
      {{ title }}
    </h1>
    <div :class="$style.subtitle">
      {{ description }}
    </div>
    <HomeExchangeBlock
      :directions="directions"
      :time="tech.time"
      :is-tech-mode="tech.on"
    />
    <PagesHomeReviews v-if="false" />
    <PagesHomeCourse v-if="false" :directions="directions" />
    <PagesHomePartner />
  </div>
</template>

<script setup lang="ts">
import { useMarkup } from '~/composables/useMarkup';
import { calculatorApi } from '~/utils/api/calculator';
import HomeExchangeBlock from '~/components/pages/home/exchange/HomeExchangeBlock.vue';
import RestoreModal from '~/components/common/RestoreModal.vue';
import { userApi } from '~/utils/api/user';
import ActivateModal from '~/components/common/ActivateModal.vue';
import { useCalculatorDataStore } from '~/stores/calculator';

definePageMeta({ auth: false });

const { t } = useI18n({
  useScope: 'local',
});
useMarkup(
  t('Обменник криптовалюты: обмен крипты на рубли - Swop.is'),
  t(
    'Быстрый обмен электронных валют по выгодным курсам! Быстрый и безопасный обмен валюты на крипту на сайте swop.is'
  )
);

const title = computed(() => t('Обменяй криптовалюту быстро и безопасно'));
const description = computed(() =>
  t(
    'Swop.is - это быстрый, безопасный и выгодный способ купить или обменять криптовалюту со множеством активных направлений'
  )
);

const directions = await calculatorApi.getDirections();

const { query } = useRoute();
const activate = async (uuid: string) => {
  try {
    await userApi.activate(uuid);
    const { $modal } = useNuxtApp();
    $modal.open(markRaw(ActivateModal));
  } catch (e: any) {
    console.log(e);
  }
};

onMounted(async () => {
  if (query?.activate) {
    await activate(query.activate as string);
  }
  if (query?.restore) {
    const { $modal } = useNuxtApp();
    $modal.open(markRaw(RestoreModal), { token: query.restore as string });
  }
  if (query?.ref) {
    useCalculatorDataStore().setRefferal(query.ref as string);
  }
});

const { tech } = await useTech();
</script>

<i18n lang="json">
{
  "ru": {
    "Обменяй криптовалюту быстро и безопасно": "Обменяй криптовалюту быстро и безопасно",
    "Swop.is - это быстрый, безопасный и выгодный способ купить или обменять криптовалюту со множеством активных направлений": "Swop.is - это быстрый, безопасный и выгодный способ купить или обменять криптовалюту со множеством активных направлений",
    "Обменник криптовалюты: обмен крипты на рубли - Swop.is": "Обменник криптовалюты: обмен крипты на рубли - Swop.is",
    "Быстрый обмен электронных валют по выгодным курсам! Быстрый и безопасный обмен валюты на крипту на сайте swop.is": "Быстрый обмен электронных валют по выгодным курсам! Быстрый и безопасный обмен валюты на крипту на сайте swop.is"
  },
  "en": {
    "Обменяй криптовалюту быстро и безопасно": "Exchange cryptocurrency quickly and safely",
    "Swop.is - это быстрый, безопасный и выгодный способ купить или обменять криптовалюту со множеством активных направлений": "Swop.is - this is a fast, safe and profitable way to buy or exchange cryptocurrency with many active directions",
    "Обменник криптовалюты: обмен крипты на рубли - Swop.is": "Swop - Exchange of electronic currencies",
    "Быстрый обмен электронных валют по выгодным курсам! Быстрый и безопасный обмен валюты на крипту на сайте swop.is": "Quick exchange of electronic currencies at favorable rates! Fast and secure exchange of currency for crypto on the site swop.is"
  }
}
</i18n>

<style lang="scss" module>
.IndexPage {
  position: relative;
}

.title {
  text-align: center;
  font-size: 4rem;
  font-weight: 500;
  line-height: 6rem;
  color: $base-100;
  cursor: default;

  @include respond-to(xs) {
    font-size: 30px;
    line-height: 134.5%;
  }
}

.subtitle {
  text-align: center;
  max-width: 55.6rem;
  margin-top: 2.5rem;
  margin-left: auto;
  margin-right: auto;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 154.5%;
  color: $base-0;
  cursor: default;

  @include respond-to(xs) {
    max-width: 325px;
    margin-top: 27px;
    font-size: 14px;
    line-height: 154.5%;
  }
}
</style>
