import type { $Fetch } from 'ofetch';
import { Auth } from '~/services/Auth';
import authMiddleware from '~/middleware/auth';

export default defineNuxtPlugin(() => {
  const {
    public: { auth },
  } = useRuntimeConfig();
  // auth.headers = useRequestHeaders(['x-forwarded-for']);
  addRouteMiddleware('auth', authMiddleware, { ...auth.middleware });
  const authService = new Auth($fetch as $Fetch);
  if (auth.useGlobalFetch) {
    globalThis.$fetch = authService.$fetch;
  }

  return {
    provide: {
      auth: authService,
    },
  };
});
