import { apiFetch } from '~/utils/fetch/fetchApi';

export const userApi = {
  register,
  activate,
  restore,
  restorePassword,
  fetchClaims,
};

interface RegisterData {
  email: string;
  password: string;
  passwordAgain: string;
  type: string;
}

interface restoreData {
  password: string;
  passwordAgain: string;
}

function register(data: RegisterData) {
  return apiFetch('/api/v1/users/signup/', {
    method: 'POST',
    body: data,
  });
}

function activate(token: string) {
  return apiFetch(`/api/v1/users/activate/${token}/`, {
    method: 'POST',
  });
}

function restore(email: string) {
  return apiFetch('/api/v1/users/restore/', {
    method: 'POST',
    body: {
      email,
    },
  });
}

function restorePassword(token: string, data: restoreData) {
  return apiFetch(`/api/v1/users/restore/${token}/`, {
    method: 'POST',
    body: data,
  });
}

function fetchClaims({ page, size }: { page: number; size: number }) {
  return apiFetch(`/api/v1/users/me/claims/`, {
    query: {
      page,
      size,
    },
    auth: true,
  });
}
