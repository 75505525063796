<template>
  <BaseModal
    :class="$style.InstructionsModal"
    :visible="visible"
    @after-enter="$emit('after-enter')"
    @after-leave="$emit('after-leave')"
    @close="$emit('close')"
  >
    <div :class="$style.title">
      {{ $t('how to make an exchange') }}
    </div>
    <div :class="$style.subtitle">{{ step }}/4 {{ title }}</div>
    <p v-if="step === 1" :class="$style.details">
      {{ $t('instruction step 1') }}
    </p>
    <img v-if="step === 1" src="/images/step1.png" alt="" :class="$style.img" />
    <img
      v-if="step === 2"
      src="/images/step2.png"
      alt=""
      :class="$style.image"
    />
    <img
      v-if="step === 3"
      src="/images/step3.png"
      alt=""
      :class="$style.image"
    />
    <img
      v-if="step === 4"
      src="/images/step4.png"
      alt=""
      :class="$style.image"
    />

    <div :class="$style.bottom">
      <div v-if="step > 1" :class="$style.back" @click="step--">
        <svg
          width="12"
          height="13"
          viewBox="0 0 12 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          :class="$style.icon"
        >
          <path
            d="M6.71 11.714C6.80373 11.621 6.87812 11.5104 6.92889 11.3885C6.97966 11.2667 7.0058 11.136 7.0058 11.004C7.0058 10.872 6.97966 10.7412 6.92889 10.6194C6.87812 10.4975 6.80373 10.3869 6.71 10.294L3.41 7.00397L11 7.00397C11.2652 7.00397 11.5196 6.89861 11.7071 6.71107C11.8946 6.52354 12 6.26918 12 6.00397C12 5.73875 11.8946 5.4844 11.7071 5.29686C11.5196 5.10932 11.2652 5.00397 11 5.00397L3.41 5.00397L6.71 1.71397C6.8983 1.52566 7.00409 1.27027 7.00409 1.00397C7.00409 0.737665 6.8983 0.482271 6.71 0.293967C6.5217 0.105663 6.2663 -0.000124479 6 -0.000124502C5.7337 -0.000124525 5.4783 0.105664 5.29 0.293968L0.290002 5.29397C0.198961 5.38907 0.127595 5.50122 0.0800005 5.62397C0.0270954 5.74367 -0.00023216 5.8731 -0.000232171 6.00397C-0.000232183 6.13484 0.0270953 6.26427 0.0800004 6.38397C0.127594 6.50672 0.198961 6.61886 0.290001 6.71397L5.29 11.714C5.38296 11.8077 5.49356 11.8821 5.61542 11.9329C5.73728 11.9836 5.86799 12.0098 6 12.0098C6.13201 12.0098 6.26272 11.9836 6.38458 11.9329C6.50644 11.8821 6.61704 11.8077 6.71 11.714Z"
            fill="#BDBDBD"
          />
        </svg>
        <span>{{ $t('back') }}</span>
      </div>
      <div :class="$style.next" @click="onNextClick">
        <span>{{ btnText }}</span>
        <svg
          v-if="step !== 4"
          width="12"
          height="13"
          viewBox="0 0 12 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          :class="[$style.icon, $style._right]"
        >
          <path
            d="M6.71 11.714C6.80373 11.621 6.87812 11.5104 6.92889 11.3885C6.97966 11.2667 7.0058 11.136 7.0058 11.004C7.0058 10.872 6.97966 10.7412 6.92889 10.6194C6.87812 10.4975 6.80373 10.3869 6.71 10.294L3.41 7.00397L11 7.00397C11.2652 7.00397 11.5196 6.89861 11.7071 6.71107C11.8946 6.52354 12 6.26918 12 6.00397C12 5.73875 11.8946 5.4844 11.7071 5.29686C11.5196 5.10932 11.2652 5.00397 11 5.00397L3.41 5.00397L6.71 1.71397C6.8983 1.52566 7.00409 1.27027 7.00409 1.00397C7.00409 0.737665 6.8983 0.482271 6.71 0.293967C6.5217 0.105663 6.2663 -0.000124479 6 -0.000124502C5.7337 -0.000124525 5.4783 0.105664 5.29 0.293968L0.290002 5.29397C0.198961 5.38907 0.127595 5.50122 0.0800005 5.62397C0.0270954 5.74367 -0.00023216 5.8731 -0.000232171 6.00397C-0.000232183 6.13484 0.0270953 6.26427 0.0800004 6.38397C0.127594 6.50672 0.198961 6.61886 0.290001 6.71397L5.29 11.714C5.38296 11.8077 5.49356 11.8821 5.61542 11.9329C5.73728 11.9836 5.86799 12.0098 6 12.0098C6.13201 12.0098 6.26272 11.9836 6.38458 11.9329C6.50644 11.8821 6.61704 11.8077 6.71 11.714Z"
            fill="currentColor"
          />
        </svg>
      </div>
    </div>
  </BaseModal>
</template>

<script setup>
import BaseModal from '~/components/common/BaseModal.vue';
const { t } = useI18n();
const step = ref(1);

const title = computed(() => {
  switch (step.value) {
    case 1:
      return t('please select the desired currency pair');
    case 2:
      return t('enter the destination and return addresses');
    case 3:
      return t('pay for the application using the specified details');
    case 4:
      return t('wait for the exchange process to complete');
  }
});

const btnText = computed(() => {
  if (step.value === 4) {
    return t('clear');
  }
  return t('next');
});
const emit = defineEmits(['close', 'after-enter', 'after-leave']);

function onNextClick() {
  if (step.value === 4) {
    emit('close');
  } else {
    step.value++;
  }
}

defineProps({
  visible: {
    type: Boolean,
    required: true,
  },
});
</script>

<style lang="scss" module>
.InstructionsModal {
  //
}

.title {
  font-weight: 400;
  font-size: 32px;
  line-height: 39px;
  color: $base-100;
}

.subtitle {
  margin-top: 1.9rem;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: $base-100;
}

.details {
  margin-top: 1.3rem;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: $base-100;
}

.img {
  object-fit: contain;
  margin-top: 2.1rem;
  width: 100%;
  height: 83px;
}

.bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2.4rem;
}

.back {
  display: flex;
  align-items: center;
  cursor: pointer;

  .icon {
    margin-right: 3px;
  }
}

.next {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5.1rem;
  width: 12.1rem;
  color: #1d2d36;
  background: linear-gradient(106.3deg, #b5d841 29.93%, #d7ff53 73.93%);
  border-radius: 50px;
  margin-left: auto;
  cursor: pointer;

  .icon {
    margin-left: 0.3rem;
    color: #1d2d36;
    transform: rotate(180deg);
  }
}

.image {
  margin-top: 19px;
  width: 100%;
  height: 252px;
  object-fit: contain;
}
</style>
