import { StorageSerializers, useSessionStorage } from '@vueuse/core';
import { getBaseUrl } from '~/utils/baseUrl';

export default async <T>(url: string) => {
  const baseUrl = getBaseUrl();

  const cached = useSessionStorage<T>(baseUrl + url, null, {
    serializer: StorageSerializers.object,
  });

  if (!cached.value) {
    const { data, error } = await useFetch<T>(baseUrl + url);

    if (error.value) {
      throw createError({
        statusCode: 500,
        statusMessage: `Could not fetch data from ${url} error: ${error.value.data}`,
        fatal: true,
      });
    }

    cached.value = data.value as T;
  }

  return cached;
};
